import { Component, effect, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, NavigationEnd, RouterModule } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { AppRouts } from 'src/app/app-routs';
import { ChildNavigationItem, NavigationItem } from 'src/app/core/types';

@Component({
  selector: 'app-nav-bar',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {
  @ViewChild('topNavRef', { static: true }) topNavRef!: ElementRef;

  @Input() pageRoute: string = ""

  isNewLogin = false

  userName = ""
  greeting = ""

  companyName: string = "ERP - Savinda Graphic Systems"
  companyShortName: string = "SGS"

  constructor(
    private router: Router,
    private authService: AuthService,
    private appRouts: AppRouts,
  ) {
    effect(() => {
      this.isNewLogin = authService.isNewLogin()
      if (this.isNewLogin) {
        const hour = new Date().getHours();
        if (hour < 12) {
          this.greeting = "Good morning, ";
        } else if (hour < 18) {
          this.greeting = "Good afternoon, ";
        } else {
          this.greeting = "Good evening, ";
        }

        setTimeout(() => {
          this.isNewLogin = false
          this.greeting = ""
        }, 6000);
      }else{
        this.greeting = ""
      }
    })
  }

  ngOnInit() {
    this.userName = sessionStorage.getItem("userName") ?? ""

    this.navigationItems = this.appRouts.getNavigationItems()

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentRoute = this.router.url;
        this.currentUrl = currentRoute
      } else {
        this.currentUrl = this.pageRoute
      }

      this.parentRoute = this.currentUrl.split('/')[1] ?? '' // Index 0 is an empty string due to leading '/'
      let completeChildRoute = this.currentUrl.split('/')[2] ?? ''
      this.childRoute = completeChildRoute.split('?')[0] ?? completeChildRoute

      let subRoutes = this.navigationItems.find(item => item.routerLink == this.parentRoute)
      if (subRoutes && subRoutes.childrenRoutes.length > 0) {
        this.childrenRoutes = subRoutes.childrenRoutes
      } else {
        this.childrenRoutes = []
      }
    });
  }

  allowedPaths: any[] = []
  navigationItems: NavigationItem[] = []

  currentUrl = ''
  parentRoute = ''
  childRoute = ''
  childrenRoutes: ChildNavigationItem[] = []


  isMenuOpened: boolean = false
  togleTopMenu() {
    this.isMenuOpened = !this.isMenuOpened
  }
  openTopMenu() {
    this.isMenuOpened = true
  }
  closeTopMenu() {
    this.isMenuOpened = false
  }
  imageClicked = false
  handleImageClick() {
    this.imageClicked = true
    setTimeout(() => {
      this.imageClicked = false
    }, 100);
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: Event): void {
    if (!this.topNavRef.nativeElement.contains(event.target)) {
      if (!this.imageClicked) {
        this.closeTopMenu()
        this.imageClicked = false
      }
    }
  }

  logOutUser() {
    this.authService.logout()
  }

  navigateToChildRoute(childRoute: string) {
    this.router.navigate([`${childRoute}`]);
  }

  isUserMenuOpened = false
  openUserMenu() {
    this.isUserMenuOpened = true
  }
  closeUserMenu() {
    this.isUserMenuOpened = false
  }

  // Function to filter out allowed paths and subRoutes
  // filterAllowedRoutes() {
  //   let allowedPermissions: any[] = this.sessionStorageService.getPermissions() ?? []
  //   console.log("allowedPermissions:", allowedPermissions);

  //   let navigationItems: any[] = this.navItems.returnNavObjects()
  //   console.log(navigationItems);

  //   // Update enable property for child routes and main part
  //   for (const item of navigationItems) {
  //     for (const childRoute of item.childrenRoutes) {
  //       if (allowedPermissions.some(route => route.path === childRoute.routerLink)) {
  //         childRoute.enable = true;
  //         item.enable = true; // Set main part enable to true if any child route is enabled
  //       }
  //     }
  //   }

  //   return navigationItems
  // }
}
