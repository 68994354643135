import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { BehaviorSubject, throwError } from 'rxjs';
import { ApiConfig } from '../../config';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    private apiUrl = `${ApiConfig.baseURL}/auth`;
    private passwordResetUrl = `${ApiConfig.baseURL}/user`;

    constructor(private http: HttpClient) { }

    login(requestBody:{username: string, password: string}) {
        return this.http.post<any>(this.apiUrl, requestBody).pipe(
            catchError((error) => {
                console.error('Login Error:', error);
                return throwError('Login failed. Please check your credentials and try again.');
            })
        );
    }

    resetPassword(requestBody:{username: string, password: string}) {
        return this.http.patch<any>(this.passwordResetUrl, requestBody).pipe(
            catchError((error) => {
                console.error('Password Reset Error:', error);
                return throwError('Reset failed. Please check your credentials and try again.');
            })
        );
    }
}
