import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';

import { LoginService } from './login.service';
import {} from '@angular/common/http';


import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
// import { AuthStateSession } from 'src/app/session-servicesll/auth.state.session.service';
// import { AuthAccessTokenSession } from 'src/app/session-servicesll/auth.access-key.session.service';
import { AuthService } from 'src/app/auth.service';
import { UserAuthStorageType } from '../../types';
import { NotificationService } from '../notification/notification.service';
import { SessionStorageService } from '../../util/session-storage.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, FormsModule,ReactiveFormsModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginService],
})
export class LoginComponent implements OnInit {

    hide = true

  constructor(
    private authService: AuthService,
    private loginService: LoginService,
    private notificationService: NotificationService,
    // private authStateSession: AuthStateSession,
    // private authAccessTokenSession: AuthAccessTokenSession,
    private sessionStorageService: SessionStorageService,
    private formBuilder: FormBuilder) {

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    // this.logOutUser()
  }

  loginForm: FormGroup;
  line = "line"

  // logOutUser() {
  //   let isLogedin = "false"
  //   this.authStateSession.setSessionStorageValue(isLogedin.toString());
  //   let access_token = ""
  //   this.authAccessTokenSession.setSessionStorageValue(access_token.toString());
  // }

  isFormsSubmitedWithErrors = false
  isSubmited = false

  onUserLogin = () => {
    this.isSubmited = true

    if (this.loginForm.valid) {
      this.loginService.login(this.loginForm.value).subscribe(
        (response) => {
          let authResult = response.data
          this.isSubmited = false
          this.line = "line"

          let userAuthData: UserAuthStorageType = {
            accessToken: authResult.access_token,
            refreshToken: authResult.refresh_token,
          }
          this.sessionStorageService.setUserToken(userAuthData)

          sessionStorage.setItem("userName", this.loginForm.get('username')?.value ?? "")

          // this.authService.setJasperAuthData(authResult.jsp_auth)
          // if (authResult.jsp_auth.JSESSIONID === "") {
          //   this.notificationService.Info('The system does not currently support PDF report generation, please contact the administrator for assistance.', 'Attention!!')
          // }

          // let permissions = [
          //   {
          //     resources: "inventory:create",
          //     path: "inventory/create"
          //   },
          //   {
          //     resources: "inventory:search",
          //     path: "inventory/search"
          //   }
          // ],

          let permissions = response.data.permission
          this.sessionStorageService.setPermissions(permissions)


          // let user_roles = [
          //   {
          //     role: "costinwg",
          //   },
          // ]

          // let userRoles = {
          //   roles: user_roles
          // }
          // this.sessionStorageService.setUserRoles(userRoles)

          try {
            if (response.data?.roles && response.data.roles.length > 0) {
              let userRoles = {
                roles: response.data.roles
              }
              this.sessionStorageService.setUserRoles(userRoles)
            }
          } catch (error) {
            console.log(error);
          }

          this.authService.login()
        },
        (error) => {
          this.isSubmited = false
          this.line = "red-line"
          this.notificationService.Error('Please insert correct User name and Password', 'Login Error')
        }
      );
    }
    else {
      this.isSubmited = false
      this.isFormsSubmitedWithErrors = true
      this.notificationService.Error("User name and password are required.", 'Login Error')
    }
  }
}
