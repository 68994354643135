<div class="z-10">
    <div class="top-bar" #topNavRef>
        <div class="top-navigation">

            <div class="menu-btn-container">
                <button class="menu-btn" (click)="togleTopMenu()">
                    <img (click)="handleImageClick()" src="../../../../assets/image/Menu icons/bars-solid.svg" alt="="
                        *ngIf="!isMenuOpened">
                    <img (click)="handleImageClick()" #topNavImgRef
                        src="../../../../assets/image/Menu icons/xmark-solid.svg" alt="X" *ngIf="isMenuOpened">
                </button>

                <div class="current-route">
                    <button (click)="togleTopMenu()">
                        <span class="hidden md:inline-block"> {{parentRoute}} </span>
                        <span *ngIf="childRoute && childRoute !== ''">
                            <span class="hidden md:inline-block bg-green"> / </span>
                            {{childRoute}}
                        </span>

                    </button>
                </div>
            </div>

            <div class="companyName">
                <p class="hidden md:block">{{companyName}}</p>
                <p class="block md:hidden">{{companyShortName}}</p>
            </div>

            <div class="profile-icon">
                <div class="flex flex-row items-center bg-[#cbcbcbad] rounded-full ">
                    <button (click)="openUserMenu()" class="w-full">
                        <img src="../assets/image/user.png" alt="user" width="24">
                    </button>

                    <div class="hidden md:block">
                        <div class=" flex flex-row items-center text-[14px] w-full px-2">
                            <span *ngIf="greeting.length > 0" class="greeting">{{greeting}}</span>
                            <p class="capitalize text-[#000000]">{{userName}}</p>
                        </div>
                    </div>
                    

                </div>
            </div>
        </div>

        @if (isMenuOpened && childrenRoutes.length > 0) {
        <div class="component-menu">
            @for (item of childrenRoutes; track $index) {
            @if (item.enable && item.icon) {
            <div class="component-menu-item">
                <button class="component-menu-btn tooltip-trigger"
                    (click)="closeTopMenu(); navigateToChildRoute(item.routerLink)">
                    <svg class="w-[20px] h-[20px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                        <path [attr.d]="item.icon" />
                    </svg>
                    <h2 class="menu-text">{{item.routerName}} </h2>
                    <span class="tooltiptext">{{item.routerName}}</span>
                </button>
            </div>
            }
            }

        </div>
        }

    </div>

    <div class="nav-bar">
        <div class="logo">
            <img src="../assets/image/Savinda icon.jpg" alt="logo">
        </div>

        <nav>
            @for (item of navigationItems; track $index) {
            @if (item.enable && item.icon !=='') {
            <div class="nav-item tooltip-trigger">
                <div>
                    <a [routerLink]="item.routerLink" [routerLinkActive]="['is-active']">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                            <path [attr.d]="item.icon" />
                        </svg>
                    </a>
                    <span class="tooltiptext">{{ item.routerName }}</span>
                </div>
            </div>
            }
            }
        </nav>

        <div class="logout tooltip-trigger">
            <button class="logout-btn" (click)="logOutUser()">
                <svg xmlns="http://www.w3.org/2000/svg" height="1.1em" viewBox="0 0 512 512">
                    <path
                        d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 192 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l210.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128zM160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32L96 32C43 32 0 75 0 128L0 384c0 53 43 96 96 96l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32l64 0z" />
                </svg>
            </button>
            <span class="tooltiptext !bg-red-300 after:!border-r-red-300 !text-black">Logout</span>
        </div>
    </div>
</div>



<div *ngIf="isUserMenuOpened" class="user-menu">
    <div class="absolute top-1 right-3  flex items-center justify-center bg-white p-0.5 rounded-full">
        <button (click)="closeUserMenu()">
            <img src="../assets/image/close.png" alt="userClose" width="28">
        </button>
    </div>
    <div class="user-menu-buttons">
        <a [routerLink]="['reset-password']" [routerLinkActive]="['is-active']" (click)="closeUserMenu()">
            Change Password
        </a>
        <a [routerLink]="['dashboard']" [routerLinkActive]="['is-active']" (click)="closeUserMenu()">
            Settings
        </a>
    </div>
</div>